import React from 'react'
import styled, { css } from 'styled-components'

const width = '50'
const height = '50'
const viewBox = '0 0 50 50'

const getDimensions = () => ({
  height,
  width
})

const getDimensionsCss = () => css`
  width: ${width}px;
  height: ${height}px;
`

const Image = styled.svg`
  ${({ noStyles }) => !noStyles ? getDimensionsCss() : null}
`

const defaultProps = {
  children: [
    <defs
      key='key-0'
    >
      <linearGradient
        id='s-b4376b1378-a'
        x1='50%'
        x2='50%'
        y1='157.789%'
        y2='20.898%'
      >
        <stop
          offset='0%'
          stopColor='#C90400'
        />
        <stop
          offset='100%'
          stopColor='#BF1A18'
        />
      </linearGradient>
    </defs>,
    <g
      fill='none'
      fillRule='evenodd'
      key='key-1'
    >
      <rect
        width='50'
        height='49.5'
        fill='url(#s-b4376b1378-a)'
        rx='1.5'
      />
      <g
        fill='#D8D8D8'
        transform='translate(18 15)'
      >
        <rect
          width='5'
          height='20'
          rx='2'
        />
        <rect
          width='5'
          height='20'
          x='9'
          rx='2'
        />
      </g>
    </g>
  ],
  viewBox
}

export default Object.assign(Image, {
  getDimensions,
  getDimensionsCss,
  defaultProps,
  displayName: 'BtnPause'
})
