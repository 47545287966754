import React from 'react'
import styled, { css } from 'styled-components'

const width = '51'
const height = '50'
const viewBox = '0 0 51 50'

const getDimensions = () => ({
  height,
  width
})

const getDimensionsCss = () => css`
  width: ${width}px;
  height: ${height}px;
`

const Image = styled.svg`
  ${({ noStyles }) => !noStyles ? getDimensionsCss() : null}
`

const defaultProps = {
  children: [
    <defs
      key='key-0'
    >
      <linearGradient
        id='s-d239d071c0-a'
        x1='50%'
        x2='50%'
        y1='157.789%'
        y2='20.898%'
      >
        <stop
          offset='0%'
          stopColor='#C90400'
        />
        <stop
          offset='100%'
          stopColor='#BF1A18'
        />
      </linearGradient>
    </defs>,
    <g
      fill='none'
      fillRule='evenodd'
      transform='translate(.5)'
      key='key-1'
    >
      <rect
        width='50'
        height='49.5'
        fill='url(#s-d239d071c0-a)'
        rx='1.5'
      />
      <path
        fill='#D8D8D8'
        d='M21.435 15.828l8.818 8.818a1 1 0 0 1 0 1.415l-8.267 8.267a1 1 0 0 1-1.707-.675l-.551-17.086a1 1 0 0 1 1.707-.739z'
      />
    </g>
  ],
  viewBox
}

export default Object.assign(Image, {
  getDimensions,
  getDimensionsCss,
  defaultProps,
  displayName: 'BtnPlay'
})
